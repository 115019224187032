import React from "react";
import { Box, Text, Button, Modal, ModalBody, ModalClose } from "flexibull2";
import styled from "styled-components";
import { RecordBanner } from "./RecordBanner";
import { useHistory } from "react-router-dom";
export const ResponsiveBox = styled(Box)`
  @media (min-width: 431px) {
    max-width: 400px;
  }

  @media (max-width: 430px) {
    max-width: 70vw;
  }

  @media (max-width: 320px) {
    width: 100vw;
  }
`;

function StatusModal(props) {
  const history = useHistory();
  const { open, close, exists, name, activeInstitution, document } = props;

  return (
    <Modal outerClick open={open} close={close} center>
      <ResponsiveBox width="80vw">
        <ModalBody>
          <ModalClose onClick={close}>&times;</ModalClose>
          <Box pad="30px" margin="10% auto">
            <Box style={{ display: "flex" }}>
              <Text size="1.6em" bold block>
                Record Status
              </Text>
            </Box>
            <Box
              display="flex"
              pad="60px 0 0 0"
              style={{
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <RecordBanner exists={exists} />
              <Text
                size="1.2em"
                bold
                block
                style={{
                  marginTop: "30px",
                }}
              >
                Hi {name}
              </Text>
              {exists ? (
                <Text
                  size="12px"
                  pad="0 40px"
                  block
                  center
                  style={{
                    margin: "20px 0",
                    textAlign: "center",
                    padding: "0 40px",
                  }}
                >
                  The record you want to verify exists. You can now proceed to
                  verifying the bearer on the document
                </Text>
              ) : (
                <Text
                  size="12px"
                  pad="0 40px"
                  block
                  center
                  style={{
                    margin: "20px 0",
                    textAlign: "center",
                    padding: "0 40px",
                  }}
                >
                  The record you want to verify does not exist. Make sure the
                  institution certificate type and matriculation number are all
                  correct and try again
                </Text>
              )}

              {exists && (
                <Button
                  round
                  pad="20px 20px"
                  style={{
                    marginBottom: "20px 0",
                    textAlign: "left",
                  }}
                  iconRight={<i className="flexibull-angle-right" />}
                  onClick={() =>
                    history.push(
                      `/verify/step-2?institution=${activeInstitution}&document=${document}&documentId=${props.documentId}`
                    )
                  }
                >
                  Verify Record Now
                </Button>
              )}
            </Box>
          </Box>
        </ModalBody>
      </ResponsiveBox>
    </Modal>
  );
}

export default StatusModal;
