import React from "react";
import { Box, Text } from "flexibull2";
import styled from "styled-components";
import RecordExist from "../../../assets/record-exist.svg";

const Wrapper = styled(Box)`
  background-color: white;
  padding: 20px 30px;
  display: flex;
  min-width: 200px;
  align-items: center;
  border-top: 7px solid #03cc85;
  flex-direction: column;
`;

export const VerificationStatusBanner = () => {
  return (
    <Wrapper>
      <Box>
        <img alt="hoto" src={RecordExist} />
      </Box>
      <Text bold size="16px" style={{ paddingTop: 5 }}>
        Record Verified
      </Text>
    </Wrapper>
  );
};
