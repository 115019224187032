import { Switch, Route } from "react-router-dom";
import SplitLogin from "./pages/Login/SplitLogin";
import Verify from "./pages/Verify";
import RemitaCallback from "./pages/RemitaCallback";
import { useAuth } from "./context/auth-context";

function App() {
  const { isAuthenticated } = useAuth();

  return (
    <Switch>
      {!isAuthenticated() ? (
        <Route exact path="/" component={SplitLogin} />
      ) : (
        <>
          <Route exact path="/verify/step-1" component={Verify} />
          <Route exact path="/verify/step-2" component={Verify} />
          <Route exact path="/verify/step-3" component={Verify} />
          <Route exact path="/remita-callback" component={RemitaCallback} />
        </>
      )}
    </Switch>
  );
}

export default App;
