import React from "react";
import { Button } from "flexibull2/build/button";
import { Input } from "flexibull2/build/input";
import { Box, Layout } from "flexibull2/build/layout";
import { SplitLoginLayout } from "flexibull2/build/login";
import { Text } from "flexibull2/build/typo";
import Logo from "../../logo.svg";

const SplitLogin = () => {
  const googleAuthURL = `https://ums-api.component.flexisaf-stage.com/oauth2/google/authorize?organization=flexisafverify&callback_url=http://safverify.flexisafapps-dev.com&product=46a54491-099b-4c7a-bd8b-9f4648c54a73`;

  return (
    <div>
      <Layout>
        <SplitLoginLayout
          image={Logo}
          brand={
            <>
              {/* <i className='flexibull-firefox' style={{ fontSize: '35px' }} /> */}
            </>
          }
        >
          <Box>
            <Box pad="10px 0">
              <h1 style={{ color: "black" }}>Get Started</h1>
              <Text>Fill in your details to continue</Text>
            </Box>
            <Input
              name="fullname"
              type="text"
              placeholder="Enter your fullname"
              label="Full Name"
              width="100%"
              spaceTop="30px"
              spaceBottom
              round
            />
            <Input
              name="phonenumber"
              type="phone"
              placeholder="Enter your phone number"
              label="Phone Number"
              width="100%"
              spaceTop="30px"
              spaceBottom
              round
            />
            <Input
              name="emailaddress"
              type="email"
              placeholder="Enter your email"
              label="email address"
              width="100%"
              spaceTop="30px"
              spaceBottom
              round
            />
          </Box>

          <Box>
            {/* <Link
              to="/verify/step-1"
              iconLeft={<i className="flexibull-angle-left" />}
              style={{
                color: "#fff",
              }}
            > */}
            <a href={`${googleAuthURL}`}>
              <Button
                round
                width={150}
                pad="0 0 0 25px"
                iconRight={<i className="flexibull-angle-right" />}
              >
                Google Login
              </Button>
            </a>

            {/* </Link> */}
          </Box>
        </SplitLoginLayout>
      </Layout>
    </div>
  );
};

export default SplitLogin;
