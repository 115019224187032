import React from "react";
import { Box } from "flexibull2";
export const MainBox = ({ children }) => {
  return (
    <Box
      display="flex"
      style={{
        backgroundColor: "#FAFAFC",
        height: "100vh",
        padding: "0 50px",
      }}
    >
      {children}
    </Box>
  );
};
