import React from "react";
import { Box, Text } from "flexibull2";

export const HR = ({ width }) => {
  return (
    <Box
      border
      width={width || "50%"}
      style={{
        borderRadius: "20px",
        border: "1px solid #D8DCE7",
        margin: "20px 0",
      }}
    >
      <Text></Text>
    </Box>
  );
};
