import React from "react";
import { Box, Text, Button, Input, Select } from "flexibull2";
import { useAuth } from "../../../context/auth-context";
import { useState } from "react";
import { Link } from "react-router-dom";
import StatusModal from "./statusModal";
import { ResponsiveBox } from "../ResponsiveBox";
import { MainBox } from "../MainBox";
import { useEffect } from "react";
import { getInstitutions, verifyDoc } from "../../../services/verify";

function CheckRecord() {
  const { data } = useAuth();
  const { firstName } = data;
  const [checkModal, setCheckModal] = useState(false);
  const [exists, setExists] = useState(false);
  const [loading, setLoading] = useState(false);
  const [institutions, setInstitutions] = React.useState([]);
  const [activeInstitution, setActiveInsitution] = React.useState({});
  const [registrationNumber, setRegistrationNumber] = React.useState("");
  const [documentType, setDocumentType] = React.useState("");
  const [documentId, setDocumentId] = React.useState();

  useEffect(() => {
    getInstitutions().then(({ data }) => {
      const modifiedInstitutions = data.map((institute) => {
        return {
          value: institute.id,
          label: institute.name,
        };
      });
      setInstitutions(modifiedInstitutions);
    });
  }, []);

  const handleInstitutionChange = (inst) => {
    setActiveInsitution(inst.value);
  };
  const handleDocumentTypeChange = (doc) => {
    setDocumentType(doc.value);
  };
  const handleRegistrationNumberChange = (e) => {
    setRegistrationNumber(e.target.value);
  };
  const handleVerifyDoc = async () => {
    setLoading(true);
    try {
      const { data } = await verifyDoc({
        registrationNumber,
        institution: activeInstitution,
        documentType,
      });
      setLoading(false);
      if (data.is_valid) {
        setDocumentId(data.document);

        setExists(true);
        setCheckModal(true);
      }
    } catch (e) {
      setLoading(false);
      setExists(false);
      setCheckModal(true);
    }
  };
  return (
    <>
      <ResponsiveBox>
        <MainBox>
          <Box>
            <Link to="/" iconLeft={<i className="flexibull-angle-left" />}>
              <Text size="18px">Back</Text>
            </Link>

            <Box maxWidth="500px" pad="40px 0">
              <Text size="24px" bold block>
                Check Record
              </Text>
              <Text size="14px" block>
                Fill in the matriculation number and institution details
              </Text>

              <Box>
                <Box pad="30px 0">
                  <Select
                    label="Select Institution"
                    placeholder="Select Institution"
                    spaceTop="30px"
                    spaceBottom
                    block
                    round
                    options={institutions}
                    onChange={handleInstitutionChange}
                  />

                  <Box pad="4px 0 0 0">
                    <Select
                      label="Select Document"
                      placeholder="Select Document Type"
                      spaceTop="30px"
                      spaceBottom
                      block
                      round
                      style={{
                        marginTop: "20px",
                      }}
                      onChange={handleDocumentTypeChange}
                      options={[
                        {
                          value: "CERTIFICATE",
                          label: "CERTIFICATE",
                        },
                      ]}
                    />
                  </Box>

                  <Input
                    name="matricnumber"
                    type="text"
                    placeholder="Candidate Matric Number"
                    label="Matric Number"
                    width="100%"
                    spaceTop="20px"
                    spaceBottom
                    round
                    onChange={handleRegistrationNumberChange}
                  />
                </Box>
                <Button
                  round
                  pad="20px 20px"
                  iconRight={<i className="flexibull-angle-right" />}
                  style={{ textAlign: "left" }}
                  onClick={handleVerifyDoc}
                  progress={loading}
                >
                  Check Record
                </Button>
              </Box>
            </Box>
          </Box>
        </MainBox>
      </ResponsiveBox>

      {checkModal && (
        <StatusModal
          open={checkModal}
          close={() => setCheckModal(!checkModal)}
          exists={exists}
          name={firstName}
          activeInstitution={activeInstitution}
          document={documentType}
          documentId={documentId}
        />
      )}
    </>
  );
}

export default CheckRecord;
