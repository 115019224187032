import React from "react";
import { Box, Text, Button } from "flexibull2";
import styled from "styled-components";
import RecordExist from "../../../assets/record-exist.svg";

const Wrapper = styled(Box)`
  background-color: white;
  padding: 20px 30px;
  width: 40%;
  display: flex;
  border-radius: 5px;
`;

export const PaymentStatusBanner = () => {
  return (
    <Wrapper>
      <Box
        style={{
          borderRight: "2px solid #FAFAFC",
          display: "flex",
          alignItems: "center",
        }}
      >
        <img alt="img" src={RecordExist} style={{ paddingRight: 20 }} />
      </Box>
      <Box
        style={{ paddingLeft: 30, display: "flex", flexDirection: "column" }}
      >
        <Text bold size="20px">
          Payment Successful
        </Text>
        <Text size="16px" style={{ padding: "20px 0" }}>
          Lorem ipsum dolor sit amet lorom dolor upsum
        </Text>
        <Button
          style={{ width: "70%", backgroundColor: "#0404c330" }}
          round
          spaceRight
        >
          View details
        </Button>
      </Box>
    </Wrapper>
  );
};
