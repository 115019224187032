import { Box, Text, Button, Checkbox } from "flexibull2";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PaymentModal from "./PaymentModal";
import { useAuth } from "../../../context/auth-context";
import { ResponsiveBox } from "../ResponsiveBox";
import { MainBox } from "../MainBox";
import { Invoice } from "./Invoice";
import { useQuery, formatCurrency } from "../../../util";
import { getFees } from "../../../services/verify";

function MakePayment() {
  const [payModal, setPayModal] = useState(false);
  const [paymentDisabled, setPaymentDisabled] = useState(false);

  const [paymentData, setPaymentData] = useState({});
  const { data: userData } = useAuth();

  const [invoiceItems, setInvoiceItems] = useState([]);
  const [total, setTotal] = useState(0);
  const query = useQuery();
  const institution = query.get("institution");
  const documentId = query.get("documentId");

  useEffect(() => {
    getFees(institution).then(({ data }) => {
      const total = data.reduce((current, next) => {
        return current + parseInt(next.amount);
      }, 0);
      setTotal(total);
      const fees = data.map((fee) => {
        return { name: fee.document_type, amount: fee.amount };
      });
      setInvoiceItems(fees);
      setPaymentData({
        institution,
        amount: 4000,
        document: documentId,
        payerName: `${userData.firstName} ${userData.lastName}`,
        payer_email: userData.username,
        payerPhone: "08033343568",
        // e9385a17-e086-4f08-b154-b2fb2e8baac3
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ResponsiveBox>
        <MainBox>
          <Box Width="600px">
            <Link
              to="/verify/step-1"
              iconLeft={<i className="flexibull-angle-left" />}
            >
              <Text size="18px">Back</Text>
            </Link>

            <Box pad="40px 0" width="50%">
              <Text size="24px" bold block style={{ marginBottom: 20 }}>
                Make Payments
              </Text>
              <Text size="14px" block>
                Lorem Ipsum Dolor Lorem Ipseum Dolor Lorem Ipsum Dolor Lorem
                Ipseum Dolor Lorem Ipsum Dolor Lorem Ipseum Dolor Lorem Ipsum
                Dolor Lorem Ipseum Dolor
              </Text>
              <Invoice items={invoiceItems} total={total} />
              <Box pad="10px 0px" style={{ display: "flex" }}>
                <Checkbox
                  box
                  elevate="high"
                  size="20px"
                  onClick={() => setPaymentDisabled(!paymentDisabled)}
                  // label="I hereby solemnly swear that i am up to no good and that i understand the terms and conditions in this undertaking that i am about to undergo"
                />
                <Text style={{ paddingTop: 5 }}>
                  I hereby solemnly swear that i am up to no good and that i
                  understand the terms and conditions in this undertaking that i
                  am about to undergo
                </Text>
              </Box>
              <Box>
                <Button
                  round
                  pad="20px 20px"
                  iconRight={<i className="flexibull-angle-right" />}
                  style={{ textAlign: "left" }}
                  onClick={() => setPayModal(!payModal)}
                  disabled={!paymentDisabled}
                >
                  Make payment
                </Button>
              </Box>
            </Box>
          </Box>
        </MainBox>
      </ResponsiveBox>

      {payModal && (
        <PaymentModal
          total={formatCurrency(total)}
          open={payModal}
          close={() => setPayModal(!payModal)}
          paymentData={paymentData}
        />
      )}
    </>
  );
}

export default MakePayment;
