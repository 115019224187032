import React from "react";
import styled from "styled-components";
import { Box, Text } from "flexibull2";
import { HR } from "../HR";
import { formatCurrency } from "../../../util";
const Wrapper = styled(Box)`
  background-color: #fcfcfd;
  padding: 20px;
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 20px 0;
  border-radius: 10px;
  border: 1px solid #e9e9eb;
`;
const LineItemStyle = styled.span`
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
`;

const LineItem = ({ name, amount }) => {
  return (
    <LineItemStyle>
      <Text size="16px">{name}</Text>
      <Text size="16px">{`NGN ${formatCurrency(amount)}`}</Text>
    </LineItemStyle>
  );
};
export const Invoice = (props) => {
  return (
    <Wrapper>
      <Text bold size="18px" style={{ marginBottom: 20 }}>
        Invoice
      </Text>
      {props.items.length > 0 &&
        props.items.map((item) => {
          return <LineItem name={item.name} amount={item.amount} />;
        })}
      <HR width="100%" />

      <LineItem name="Total" amount={props.total} />
    </Wrapper>
  );
};
