// import { css } from 'styled-components';
// import { darken, lighten, transparentize } from 'polished';
const PrimaryTheme = "#0079ff";
// const PrimaryGrey = '#62707B';

export const Theme = {
  PrimaryColor: PrimaryTheme,
  PrimaryGrey: "#FAFAFC",

  PrimaryBackground: "#fff",
  PrimaryGreen: "#4DCCBD",
  PrimaryMint: "#95fd35",
  PrimaryRed: "#E15554",
  PrimaryYellow: "#F2C02E",
  PrimaryOrange: "#FFA200",
  PrimaryBlue: "#3158D8",
  SecondaryColor: "#be7efc",
  TetiaryColor: "#ecdbfe",

  PrimaryFontColor: "#373737",

  PrimaryRadius: "30px",
  SecondaryRadius: "5px",

  PrimaryBorderColor: "#E6E9F0",
  PrimaryFade: "#fff",
  PrimaryInputOutline: "#9D9DB7",
};
