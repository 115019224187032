import styled from "styled-components";
import { Text, Box } from "flexibull2";
import Logo from "../../../assets/atbu.png";
import { mapDegreeToText } from "../../../services/utils";

export const ResultElement = styled.div`
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  -webkit-print-color-adjust: exact !important;
  background-size: cover;
  background-color: #f8f7ef;
  background-position: center;
  background-blend-mode: overlay;

  max-width: 1000px;
  margin: 0 auto;
  border-radius: 5px;
  border: 1px solid #fbee88;
  padding: 50px 0;
  overflow: hidden;

  text-align: center;
  & img {
    height: 120px;
  }
  }
`;

export const Name = styled.div`
  font-size: 42px;
  font-family: "Brush Script MT", cursive;
  @media (max-width: 630px) {
    font-size: 21px;
  }
`;
export const Certificate = ({ data }) => {
  const { institution, graduate } = data;
  const { name } = institution;
  const {
    first_name,
    last_name,
    other_names,
    course_of_study,
    class_of_degree,
  } = graduate;
  return (
    <ResultElement>
      <Text bold size="32px">
        {name}
      </Text>
      <Box style={{ padding: "20px 0" }}>
        <img alt="school logo" src={Logo} />
      </Box>

      <Text bold size="20px" style={{ margin: "20px 0" }}>
        This is to certify that
      </Text>
      <Name>{`${first_name} ${other_names} ${last_name}`}</Name>

      <Box style={{ padding: "20px 0" }}>
        <Text size="16px">
          Having completed an approved course of study and passed the prescribed
          examination, has this day, under the authority of the senate, been
          awarded the degree of
        </Text>
      </Box>

      <Name>{course_of_study}</Name>
      <Name>with {mapDegreeToText(class_of_degree)} </Name>
    </ResultElement>
  );
};
