import React, { useEffect, useState } from "react";
import { Layout, Box, Text, Grid, Button, Loader } from "flexibull2";
import { useAuth } from "../../context/auth-context";
import { Theme } from "../config/theme";
import { useHistory } from "react-router-dom";
import { verifyTransaction } from "../../services/verify";
import { useQuery } from "../../util";
import { ResponsiveBox } from "../components/ResponsiveBox";

function Verify() {
  const [loading, setLoading] = useState(false);
  const [paymentData, setPaymentData] = useState({});
  const { data, logout } = useAuth();
  const history = useHistory();
  const query = useQuery();

  const { firstName, lastName } = data;
  const reference = query.get("orderID");
  useEffect(() => {
    setLoading(true);
    verifyTransaction(reference)
      .then(({ data }) => {
        setLoading(false);
        setPaymentData(data);
      })
      .catch(() => {
        setLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { reg_number, document_type } = paymentData;
  return (
    <Layout
      theme={Theme}
      withTopNav
      topNavProps={{
        background: "white",
        elevate: "none",
        scrollable: true,
      }}
      topNavContent={
        <>
          <ResponsiveBox
            display="flex"
            width="100%"
            style={{
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <Box
              display="flex"
              style={{
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Text size="20px" bold color={Theme.PrimaryColor}>
                SafVerify
              </Text>
            </Box>
            <Box
              display="flex"
              style={{ alignItems: "center", justifyContent: "end" }}
            >
              <Box onClick={logout}>
                <Text>Logout</Text>
              </Box>
              <Box margin="0 10px 0 0 ">
                <Box
                  background={Theme.PrimaryGrey}
                  display="flex"
                  width="40px"
                  height="40px"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "100%",
                  }}
                >
                  <i
                    className="saf-user-outline"
                    style={{ fontSize: "14px" }}
                  />
                </Box>
              </Box>

              <Box>
                <Text>{`${firstName} ${lastName}`}</Text>
              </Box>
            </Box>
          </ResponsiveBox>
        </>
      }
    >
      <Box pad="50px 0 0 0">
        <ResponsiveBox>
          <Box
            pad="50px 50px"
            height="100vh"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <Grid
                default="1fr"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                {loading ? (
                  <Box vAlign height={`calc(100vh - 200px)`}>
                    <Box margin="0 auto" align="center">
                      <Loader color={Theme.PrimaryColor} />
                    </Box>
                  </Box>
                ) : (
                  <Box
                    width="30%"
                    style={{ textAlign: "center", paddingTop: 30 }}
                  >
                    <Button
                      onClick={() =>
                        history.push(
                          `/verify/step-3?reference=${reference}&regNumber=${reg_number}&documentType=${document_type}`
                        )
                      }
                      spaceRight
                      pale
                      iconRight={<i className="flexibull-angle-right" />}
                    >
                      View Document
                    </Button>
                  </Box>
                )}
              </Grid>
            </Box>
          </Box>
        </ResponsiveBox>
      </Box>
    </Layout>
  );
}

export default Verify;
