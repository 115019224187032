import axios from "axios";
import { API_URL } from "./utils";

export const getInstitutions = () => {
  return axios.get(`${API_URL}/institutions`);
};

export const verifyDoc = (data) => {
  const { registrationNumber, institution, documentType, paymentReference } =
    data;
  return axios.get(
    `${API_URL}/documents/verify?reg_number=${registrationNumber}&institution=${institution}&document_type=${documentType}&payment_reference=${paymentReference}`
  );
};

export const getFees = (institution) => {
  return axios.get(`${API_URL}/fees?institution=${institution}`);
};

export const verifyTransaction = (reference) => {
  return axios.get(`${API_URL}/payments/${reference}/verify`);
};

export const initPayment = (data) => {
  return axios.post(`${API_URL}/payments`, data);
};

export const getDocument = (data) => {
  const { registrationNumber, institution, documentType, paymentReference } =
    data;
  return axios.get(
    `${API_URL}/documents?reg_number=${registrationNumber}&institution=${institution}&document_type=${documentType}&payment_reference=${paymentReference}`
  );
};
