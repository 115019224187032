import React from "react";
import { Layout, Box, Text } from "flexibull2";
import { useAuth } from "../../context/auth-context";
import { Theme } from "../config/theme";
import { Switch, Route } from "react-router-dom";
import CheckRecord from "../components/Verification/checkRecord";
import MakePayment from "../components/Verification/MakePayment";
import Status from "../components/Verification/Status";
import { ResponsiveBox } from "../components/ResponsiveBox";

function Verify() {
  const { data, logout } = useAuth();

  const { firstName, lastName } = data;
  return (
    <Layout
      theme={Theme}
      withTopNav
      topNavProps={{
        background: "white",
        elevate: "none",
        scrollable: true,
      }}
      topNavContent={
        <>
          <ResponsiveBox
            display="flex"
            width="100%"
            style={{
              justifyContent: "space-between",
              padding: 10,
            }}
          >
            <Box
              display="flex"
              style={{
                alignItems: "center",
                justifyContent: "start",
              }}
            >
              <Text size="20px" bold color={Theme.PrimaryColor}>
                SafVerify
              </Text>
            </Box>
            <Box
              display="flex"
              style={{ alignItems: "center", justifyContent: "end" }}
            >
              <Box onClick={logout}>
                <Text>Logout</Text>
              </Box>
              <Box margin="0 10px 0 0 ">
                <Box
                  background={Theme.PrimaryGrey}
                  display="flex"
                  width="40px"
                  height="40px"
                  style={{
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "100%",
                  }}
                >
                  <i
                    className="saf-user-outline"
                    style={{ fontSize: "14px" }}
                  />
                </Box>
              </Box>

              <Box>
                <Text>{`${firstName} ${lastName}`}</Text>
              </Box>
            </Box>
          </ResponsiveBox>
        </>
      }
    >
      <Box pad="50px 0 0 0">
        <Switch>
          <Route exact path="/verify/step-1" component={CheckRecord} />

          <Route exact path="/verify/step-2" component={MakePayment} />
          <Route exact path="/verify/step-3" component={Status} />
        </Switch>
      </Box>
    </Layout>
  );
}

export default Verify;
