import React from "react";
import { useLocation } from "react-router-dom";
const formatCurrency = (value) => new Intl.NumberFormat().format(value);

export { formatCurrency };

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
