import React, { useState } from "react";
import {
  Box,
  Text,
  Modal,
  ModalBody,
  ModalClose,
  ModalFooter,
} from "flexibull2";
import { CardSelector } from "flexibull2/build/cardselectors";

import RemitaIcon from "../../../assets/remita_orange_new_logo.svg";
// import FlutterWaveIcon from "../../../assets/flutterwave-1.svg";
// import Paypal from "../../../assets/paypal-seeklogo.com.svg";
// import PayStack from "../../../assets/paystack-2.svg";
import styled from "styled-components";
import { Button } from "flexibull2/build/button";
import { Grid } from "flexibull2/build/layout";
import { initPayment } from "../../../services/verify";
import RemitaButton from "../RemitaButton";

const Container = styled.div`
  .haCnlE + label {
    border-radius: 0px !important;
    filter: none !important;
    width: 200px !important;
    height: 200px !important;
    border-top: 4px solid blue;
  }
`;

function PaymentModal(props) {
  const { open, close, total, paymentData } = props;
  const [paymentInfo, setPaymentInfo] = useState({});
  const initiatePayment = () => {
    initPayment(paymentData).then((response) => {
      setPaymentInfo(response.data);
    });
  };
  return (
    <Modal outerClick open={open} close={close} left bottom>
      <ModalBody style={{ margin: 0 }}>
        <ModalClose onClick={close}>&times;</ModalClose>
        <Box pad="30px" margin="20px">
          <Box style={{ display: "flex", marginLeft: "10px" }}>
            <Text size="2em" bold block>
              Select a payment client you prefare
            </Text>
          </Box>
          <Box
            style={{
              marginTop: "10px",
              fontWeight: "300",
              marginBottom: "40px",
              marginLeft: "10px",
            }}
          >
            <Text size="1.2em" block>
              Choose a payment gateway you are most comfortable with
            </Text>
          </Box>
          <Container>
            {/* <CardSelector
              name="select"
              value="1"
              view="icon"
              label="Paystack"
              icon={
                <img
                  src={PayStack}
                  alt="PayStack icon"
                  style={{
                    width: "100%",
                  }}
                />
              }
            />
            <CardSelector
              name="select"
              value="1"
              view="icon"
              label="Flutterwave"
              icon={<img src={FlutterWaveIcon} alt="Flutterwave icon" />}
            /> */}
            <CardSelector
              name="select"
              value="1"
              view="icon"
              label="Remita"
              icon={<img src={RemitaIcon} alt="Remita icon" />}
            />
            {/* <CardSelector
              name="select"
              value="1"
              view="icon"
              label="Paypal"
              icon={<img src={Paypal} alt="Paypal icon" />}
            /> */}
          </Container>
          <Box
            style={{
              marginTop: "40px",
              marginBottom: "40px",
            }}
          >
            {/* <Link to="/verify/step-3"> */}
            <Button
              round
              spaceRight
              iconRight={<i className="flexibull-angle-right" />}
            >
              <Grid default="1fr 1fr">
                <Box aling="left">&#8358; {total}</Box>
                {paymentInfo.rrr ? (
                  <RemitaButton {...paymentInfo} />
                ) : (
                  <Box aling="right" onClick={initiatePayment}>
                    Continue
                  </Box>
                )}
              </Grid>
            </Button>
            {/* </Link> */}
          </Box>
        </Box>
        <ModalFooter></ModalFooter>
      </ModalBody>
    </Modal>
  );
}

export default PaymentModal;
