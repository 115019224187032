export const API_URL = "http://api.safverify.flexisafapps-dev.com";

const degreeMap = {
  1: "first class honours",
  2: "second class honours",
  3: "third class honours",
};

export const mapDegreeToText = (degree) => {
  return degreeMap[degree];
};
