import React, { useEffect, useState } from "react";
import { Box, Text, Loader } from "flexibull2";
import { Theme } from "../../config/theme";
import { ResponsiveBox } from "../ResponsiveBox";
import { Link } from "react-router-dom";
import { HR } from "../HR";
import { PaymentStatusBanner } from "./PaymentStatusBanner";
import { VerificationStatusBanner } from "./VerificationStatusBanner";
import { useQuery } from "../../../util";
import { getDocument } from "../../../services/verify";

import { MainBox } from "../MainBox";

import { Certificate } from "./Certificate";
function Status() {
  const [certificateData, setCertificateData] = useState({});
  const [loading, setLoading] = useState(false);

  const query = useQuery();
  const paymentReference = query.get("reference");
  const registrationNumber = query.get("regNumber");
  const documentType = query.get("documentType");

  useEffect(() => {
    setLoading(true);
    getDocument({
      registrationNumber,
      documentType,
      institution: "4daf8e89-11fd-4292-b4fb-27389b9e6063",
      paymentReference,
    }).then(({ data }) => {
      setLoading(false);
      setCertificateData(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <ResponsiveBox>
        <MainBox>
          <Box style={{ width: "100%" }}>
            <Link
              to="/verify/step-2"
              iconLeft={<i className="flexibull-angle-left" />}
            >
              <Text size="18px">Back</Text>
            </Link>

            <Box pad="40px 0" width="50%">
              <Text bold size="24px">
                Payment Status
              </Text>
            </Box>
            <HR width="100%" />
            <Box>
              <PaymentStatusBanner />
            </Box>
            <Box pad="40px 0" width="50%">
              <Text bold size="24px">
                Record Verification
              </Text>
            </Box>
            <HR width="100%" />

            <Box style={{ display: "flex" }}>
              <Box>
                <VerificationStatusBanner />
              </Box>
              <Box style={{ flexGrow: 1, margin: "0 20px", height: 300 }}>
                {loading ? (
                  <Box vAlign height={`calc(100vh - 200px)`}>
                    <Box margin="0 auto" align="center">
                      <Loader color={Theme.PrimaryColor} />
                    </Box>
                  </Box>
                ) : (
                  certificateData.institution && (
                    <Certificate data={certificateData} />
                  )
                )}
              </Box>
            </Box>
          </Box>
        </MainBox>
      </ResponsiveBox>
    </>
  );
}

export default Status;
