import React from "react";
import styled from "styled-components";
import { Box } from "flexibull2/build/layout";

export const ResponsiveBox = styled(Box)`
  z-index: 3;
  margin: 0 auto;
`;

function index() {
  return <div></div>;
}

export default index;
