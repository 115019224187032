import React from "react";
import styled from "styled-components";
import { Box, Text } from "flexibull2";
import RecordExist from "../../../assets/record-exist.svg";
import RecordDoesNotExist from "../../../assets/record-does-not-exist.svg";

const BannerBox = styled(Box)`
  background-color: #fcfcfd;
  width: 60%;
  align-items: center;
  padding: 20px;
  border-top: ${(props) =>
    !props.exists ? "3px solid #ff4040" : "3px solid #03cc85"};
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #eeeff3;
  border-right: 1px solid #eeeff3;
  border-left: 1px solid #eeeff3;
`;

export const RecordBanner = ({ exists }) => {
  return (
    <BannerBox exists={exists}>
      <img alt="" src={exists ? RecordExist : RecordDoesNotExist} width={30} />
      {exists ? (
        <Text size="16px" bold block>
          Record Exists
        </Text>
      ) : (
        <Text size="14px" bold block>
          Record Does not exist
        </Text>
      )}
    </BannerBox>
  );
};
