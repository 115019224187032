import * as React from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import qs from "query-string";

const AuthContext = React.createContext();
function AuthProvider(props) {
  const history = useHistory();
  function getUserFromLocalStorage() {
    return JSON.parse(localStorage.getItem("safverify_user")) || {};
  }
  const [data, setData] = React.useState(getUserFromLocalStorage);
  const [isAuthed, setIsAuthed] = React.useState(false);

  React.useEffect(() => {
    const { transfer_code } = qs.parse(window.location.search);
    if (transfer_code) {
      axios
        .post(
          `https://ums-api.component.flexisaf-stage.com/auth/products/46a54491-099b-4c7a-bd8b-9f4648c54a73/organisations/flexisafverify/transfer`,
          {
            code: transfer_code,
          }
        )
        .then(async ({ data }) => {
          const { user, access_token } = data;
          axios.defaults.headers.common["Authorization"] = access_token;
          await localStorage.setItem("safverify_user", JSON.stringify(user));
          setData(user);
          await localStorage.setItem(
            "safverify_token",
            JSON.stringify(access_token)
          );
          setIsAuthed(true);
          history.push("/verify/step-1");
        });
    } else {
      if (isAuthenticated() && window.location.pathname === "/") {
        history.push("/verify/step-1");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthed]);

  const isAuthenticated = () => {
    return Boolean(localStorage.getItem("safverify_token"));
  };
  const logout = () => {
    localStorage.removeItem("safverify_token");
    localStorage.removeItem("safverify_user");
    history.replace(`/`);
    window.location.reload();
  }; // clear the token in localStorage and the user data
  return (
    <AuthContext.Provider
      value={{
        data,
        setData,
        isAuthenticated,
        logout,
      }}
      {...props}
    />
  );
}
const useAuth = () => React.useContext(AuthContext);
export { AuthProvider, useAuth };
